<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn to="/verification" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 2 of 6
        </p>
        <p class="headline font-weight-black mb-2">Upload PAN card</p>

        <p class="subtitle-1 font-weight-regular txt-666">We weren't able to get your e-PAN card from Digilocker, so kindly upload your physical PAN card.</p>
        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.panproof == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your PAN proof have been rejected due <b>{{ rejectdata.PAN_reject_reason }}</b></span
          >
        </v-alert>
        <div v-if="!dataloader" class="pt-4">
          <div v-if="!panFile && !panreject" class="mb-16 mb-md-0">
            <div class="form-group">
              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''" @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false" class="fileupload elevation-0 rounded-lg text-center" style="border: 1px dashed #d0d3d6">
                <div class="innerfilup">
                  <img class="mb-2" src="@/assets/file drop.svg" width="48px" />
                  <p class="font-weight-bold fs-18" style="color: #0037b7 !important">Click here to upload</p>
                  <p class="mb-0 txt-666 subtitle-1 font-weight-regular" style="line-height: 18px">You can drop your here PAN be uploaded</p>
                </div>
                <input type="file" id="items" accept="image/png, image/jpg, image/jpeg" name="items[]" @change="onChange" />
              </v-card>
            </div>
          </div>

          <div v-else>
            <p>
              <span class="txt-666">File names</span> : <b>{{ panimagedata.name }}</b>
            </p>
            <div class="text-right mb-2">
              <v-card width="100%" class="elevation-0 mb-16 mb-md-0">
                <iframe style="border: thin dashed #cfd8dc !important; border-radius: 8px" :src="panimage" width="100%" height="240"></iframe>
              </v-card>
            </div>
          </div>
          <v-row no-glutters class="mt-4 d-none d-md-flex">
            <v-col cols="6" class="text-right">
              <v-btn height="48px" @click="panDatac()" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn height="48px" :disabled="!panFile" @click="panreject ? $emit('clicked', 'personal') : fileUpload()" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
            </v-col>
          </v-row>
          <v-footer padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-row>
              <v-col cols="6">
                <v-btn height="48px" @click="panDatac()" color="#444" outlined style="border: 2px solid #444 !important" block class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn height="48px" :disabled="!panFile" block @click="panreject ? $emit('clicked', 'personal') : fileUpload()" :loading="cardloader" color="#000" class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
                >
              </v-col>
            </v-row>
          </v-footer>
        </div>

        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/onb4.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
export default {
  data: () => ({
    name: "",
    /////////////////
    dragging: false,

    panFile: "",
    panreject: false,
    panimage: "",
    panimagedata: [],
    bankpass: "",
    cardloader: false,
    stageis: "",
    dataloader: null,
    IncomeProofselect: null,
    rejectdata: [],
  }),

  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (
          response.data.data_type &&
          response.data.stage_wise_approval.panproof != "yes" &&
          (p.stage == "panproof" || p.stage == "personal" || p.stage == "bank" || p.stage == "bankproof" || p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")
        ) {
          axiosThis.$emit("clicked", 2, response.data);
          axiosThis.stageis = p;
          response.data.app_status == "rejected" && response.data.stage_wise_approval.panproof == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },

  mounted() {
    this.dataloader = true;

    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        if ("pan_Status" in response.data && response.data["pan_Status"]) {
          var configq = {
            method: "post",
            url: api.api_url + "/check_file?mob=" + atob(localStorage.getItem("hash")),
            headers: {},
          };
          axios(configq)
            .then(function (response) {
              if (response.data != "no file data found") {
                axiosThis.IncomeProofselect = response.data.incometype == "Bank statement" ? true : false;

                if ("pan_Status" in response.data && response.data["pan_Status"]) {
                  var panresponse = JSON.parse(response.data["pan_Status"]);

                  if ("pan" in panresponse && panresponse["pan"] == true) {
                    axiosThis.$emit("clicked", "personal");
                  }
                }
                if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.panproof == "no") {
                  axiosThis.panreject = false;
                  axiosThis.dataloader = false;
                } else if (response.data["Pan File"] == true) {
                  axiosThis.panreject = true;
                  let name = "PAN";
                  let link = response.data["Pan"];
                  axiosThis.getFileis(name, link);
                }
                axiosThis.dataloader = false;
              } else {
                axiosThis.dataloader = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              axiosThis.$emit("snack", 0, error);
            });
        } else {
          axiosThis.$emit("clicked", "personal");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },

  methods: {
    subtractMonth(date, n) {
      date.setMonth(date.getMonth() - n);
      var strArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var m = strArray[date.getMonth()];
      var y = `${date.getFullYear()}`.slice(2);
      return m + " " + y;
    },
    currentMonth(date) {
      var strArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var m = strArray[date.getMonth()];
      var y = `${date.getFullYear()}`.slice(2);
      return m + " " + y;
    },
    panDatac() {
      this.panFile = "";
      this.panimagedata = [];
      this.panimage = "";
      this.panreject = false;
      this.dragging = false;
      this.$emit("changed", 2);
    },

    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      if ((file["type"] == "image/jpeg" || file["type"] == "image/jpg" || file["type"] == "image/png") && file.size <= 4000000) {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, file.size > 4000000 ? "Img size should be less than 4 MB!" : "Only png, jpg, jpeg image formats are supported.");
      }
    },
    getFileis(name, link) {
      let config = {
        method: "post",
        url: api.api_url + `/filsend?path=${link}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            let hash = atob(localStorage.getItem("hash"));
            if (name == "PAN") {
              let url = `${api.api_img}/${link}`;
              let blob = new Blob([url]);
              let file = new File([blob], `${url.split(`${hash}/`)[1].split(".")[0]}`, {type: `image/${url.split(`${hash}/`)[1].split(".")[1]}`});
              axiosThis.panimage = url;
              axiosThis.panFile = file;
              axiosThis.panimagedata["name"] = axiosThis.panFile.name;
              axiosThis.panimagedata["size"] = (axiosThis.panFile.size / 1048576).toFixed(1);
              axiosThis.panimagedata["from"] = "be";
            }
            axiosThis.dataloader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    fileUpload() {
      if (this.panreject == false && this.panFile && (this.panFile["type"] == "image/jpeg" || this.panFile["type"] == "image/jpg" || this.panFile["type"] == "image/png")) {
        this.cardloader = true;
        let formData = new FormData();

        if (this.panreject == false && this.panFile) {
          formData.append("pancard", this.panFile);
        }
        var axiosThis = this;

        axios
          .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.data["msg"] == "All Files Upload sucessfully") {
              axiosThis.$emit("changed", 0);
              if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.panproof == "no") {
                axiosThis.$emit("steged", "panproof");
              } else {
                axiosThis.$emit("clicked", "personal");
              }
            } else {
              axiosThis.cardloader = false;
              axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
            }
          })
          .catch(function () {
            axiosThis.cardloader = false;
          });
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, "Only png, jpg, jpeg image formats are supported.");
      }
    },
  },
};
</script>
