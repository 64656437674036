<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols pr-md-8">
        <div v-if="!dataloader">
          <img src="@/assets/complete.svg" />
          <p class="headline font-weight-black mb-2">Woohoo {{ panname.Name ? panname.Name : "..." }}!</p>
          <p class="subtitle-1 font-weight-regular txt-666" style="line-height: 20px">
            Your application is in. 🎉 It’ll take around 3-4 days for verification and activation as per regulatory requirements. We’ll keep you posted via SMS or app notifications—sit back and relax!
          </p>

          <p class="mt-8 txt-666 font-weight-medium mb-0">Progress status</p>

          <v-stepper v-model="ps" vertical class="elevation-0 stepper-c mb-0 pb-0">
            <v-stepper-step :complete="ps >= 1" step="1" color="#43A833" class="pl-0 fs-18 font-weight-bold">
              Application Submitted
              <small class="mt-1 subtitle-2"> Your form’s in, and you’re one step closer to trading greatness!</small>
            </v-stepper-step>

            <v-stepper-content step="1" class="ml-4"> </v-stepper-content>

            <v-stepper-step :complete="ps >= 2" step="2" color="#43A833" icon="mdi-timelapse" class="pl-0 fs-18 font-weight-bold">
              Inspection in Progress
              <small class="mt-1 subtitle-2"> Our team is reviewing your details with care. Almost there!</small>
            </v-stepper-step>

            <v-stepper-content step="2" class="ml-4"> </v-stepper-content>

            <v-stepper-step :complete="ps >= 3" step="3" color="#43A833" class="pl-0 fs-18 font-weight-bold">
              Account Activations
              <small class="mt-1 subtitle-2">The moment your account is live, we’ll ping you via SMS or notifications. Get ready to trade!</small>
              <!-- <small class="mt-1 subtitle-2">We'll update via SMS or notification once your account is activated.</small> -->
            </v-stepper-step>
          </v-stepper>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <a href="https://ref.zebull.in/" target="_blank">
            <img src="@/assets/submit.svg" class="rgt-img" alt="rgt-img" />
          </a>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
export default {
  data: () => ({
    panname: [],
    ps: 1,
    dataloader: null,
  }),
  created() {
    this.dataloader = true;
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && p.stage == "complete") {
          axiosThis.$emit("clicked", 7, response.data);
          if (!response.data.msg) {
            if (p.stage == "complete") {
              if (response.data.changes && response.data.changes == "reviewcomplete") {
                axiosThis.ps = 3;
              } else if (response.data.app_status && response.data.app_status == "approved") {
                axiosThis.ps = 2;
              } else {
                axiosThis.ps = 1;
              }
              axiosThis.getNamedata();
            } else if (p.stage.length > 0) {
              // axiosThis.$router.push({ name: p.stage });
            }
          } else {
            axiosThis.$router.push("/");
          }
          axiosThis.dataloader = false;
          // mobile_reject_reason
          // email_reject_reason
          // PAN_reject_reason
          // digilocker_reject_reason
          // personal_reject_reason
          // bank_reject_reason
          // finan_reject_reason
          // sign_reject_reason
          // image_reject_reason
          // nominee_reject_reason
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },

  methods: {
    getNamedata() {
      var json_data = {
        mobile: atob(localStorage.getItem("hash")),
      };
      var config = {
        method: "post",
        url: api.api_url + "/getSignupPAN",
        headers: {"Content-Type": "application/json"},
        data: JSON.stringify(json_data),
      };
      let axiosThis = this;
      axios(config)
        .then(function (response) {
          if (response.data.Name) {
            axiosThis.panname = response.data;
          }
          localStorage.clear();
          sessionStorage.clear();
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
  },
};
</script>
