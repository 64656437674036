<template>
  <div>
    <v-row no-glutters class="body-row">
      <v-col cols="12" md="7" class="left-cols pr-md-8" :class="cardloader ? 'poi-n' : ''">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Back
        </p>
        <p class="headline font-weight-black mb-2">Add your E-mail Address</p>
        <p class="subtitle-1 font-weight-regular txt-666">We need your email address to send account related updates</p>

        <div class="d-none d-md-inline-flex mb-8">
          <v-btn @click="(typemannual = false), googleSignIn()" large class="rounded-pill elevation-0 mr-4" :color="typemannual == false ? '#000' : '#fff'" style="border: 1.6px solid var(--stone-gray-stone-gray-200, #dae1e9) !important"
            ><img class="mr-2" width="20px" src="@/assets/google.svg" /><span class="subtitle-1 text-none" :class="typemannual == false ? 'white--text' : 'black--text'"><b>Continue with Google</b></span></v-btn
          >
          <v-btn @click="(typeBtn = false), (typemannual = true)" large class="rounded-pill elevation-0" :color="typemannual ? '#000' : '#fff'" style="border: 1.6px solid var(--stone-gray-stone-gray-200, #dae1e9) !important">
            <img v-if="typemannual" class="mr-2" width="22px" src="@/assets/addmail1.svg" />
            <img v-else class="mr-2" width="22px" src="@/assets/addmail.svg" />

            <span class="subtitle-1 text-none" :class="typemannual ? 'white--text' : 'black--text'"><b>Add e-mail manually</b></span></v-btn
          >
        </div>

        <v-row class="d-md-none mb-8">
          <v-col cols="12">
            <v-btn @click="(typemannual = false), googleSignIn()" large class="rounded-pill elevation-0 mr-4" :color="typemannual == false ? '#000' : '#fff'" block style="border: 1.6px solid var(--stone-gray-stone-gray-200, #dae1e9) !important"
              ><img class="mr-2" width="20px" src="@/assets/google.svg" /><span class="subtitle-1 text-none" :class="typemannual == false ? 'white--text' : 'black--text'"><b>Continue with Google</b></span></v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn @click="(typeBtn = false), (typemannual = true)" large class="rounded-pill elevation-0" :color="typemannual ? '#000' : '#fff'" block style="border: 1.6px solid var(--stone-gray-stone-gray-200, #dae1e9) !important">
              <img v-if="typemannual" class="mr-2" width="22px" src="@/assets/addmail1.svg" />
              <img v-else class="mr-2" width="22px" src="@/assets/addmail.svg" />

              <span class="subtitle-1 text-none" :class="typemannual ? 'white--text' : 'black--text'"><b>Add e-mail manually</b></span></v-btn
            >
          </v-col>
        </v-row>

        <div v-if="typemannual">
          <p class="font-weight-medium fs-17 mb-0">
            Enter email address
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path
                d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                fill="#EF5350"
              />
            </svg>
          </p>

          <v-text-field @keyup="lowercase" v-model="email" :error-messages="emailErrors" @keypress="ash = false" :class="Otp_screen ? 'txtfieldd' : 'txtfield'" @keyup.enter="verifyEmail()" required :readonly="Otp_screen" type="email" placeholder="Enter email address" class="txt-field">
            <template #prepend-inner>
              <div class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M1 8.04199V12.6253C1 12.99 1.14487 13.3397 1.40273 13.5976C1.66059 13.8555 2.01033 14.0003 2.375 14.0003H13.375C13.7397 14.0003 14.0894 13.8555 14.3473 13.5976C14.6051 13.3397 14.75 12.99 14.75 12.6253V8.04199"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                  <path
                    d="M14.75 5.75V4.375C14.75 4.01033 14.6051 3.66059 14.3473 3.40273C14.0894 3.14487 13.7397 3 13.375 3H2.375C2.01033 3 1.66059 3.14487 1.40273 3.40273C1.14487 3.66059 1 4.01033 1 4.375V5.75L7.875 9.41667L14.75 5.75Z"
                    stroke="#666666"
                    stroke-width="1.6"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </template>
            <template #append>
              <a v-if="Otp_screen" style="text-decoration: none; color: #0037b7; font-size: 12px" href="/email"><b>Change E-mail</b></a>
            </template>
          </v-text-field>

          <div v-if="Otp_screen" class="mt-6">
            <p class="font-weight-medium fs-17 mb-0">
              Enter 4 digit OTP sent to your e-mail<svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <path
                  d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                  fill="#EF5350"
                />
              </svg>
            </p>

            <v-text-field
              placeholder="0000"
              ref="emailotp"
              required
              type="number"
              hide-spin-buttons
              v-model="otpemail"
              :readonly="cardloader"
              @input="otpVerifyemail()"
              @keypress="OtpeValidation($event), (ash = false)"
              @keyup.enter="otpVerifyemail()"
              counter="4"
              class="txt-field"
              :error-messages="eotpErrors"
              @blur="$v.otpemail.$touch()"
            >
              <template #prepend-inner>
                <div class="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_419_1733)">
                      <path
                        d="M15 0H7.5C7.23478 0 6.98043 0.105357 6.79289 0.292893C6.60536 0.48043 6.5 0.734784 6.5 1V8.5C6.5 8.59286 6.52586 8.68388 6.57467 8.76287C6.62349 8.84185 6.69334 8.90569 6.77639 8.94721C6.85945 8.98874 6.95242 9.00632 7.0449 8.99798C7.13738 8.98964 7.22572 8.95571 7.3 8.9L9.1665 7.5H15C15.2652 7.5 15.5196 7.39464 15.7071 7.20711C15.8946 7.01957 16 6.76522 16 6.5V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM11 5.5H8.5V4.5H11V5.5ZM14 3H8.5V2H14V3Z"
                        fill="#666666"
                      />
                      <path
                        d="M10.5 8.5V13.5C10.5 13.6326 10.4473 13.7598 10.3536 13.8536C10.2598 13.9473 10.1326 14 10 14H2.5C2.36739 14 2.24021 13.9473 2.14645 13.8536C2.05268 13.7598 2 13.6326 2 13.5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H5.5V1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H10C10.3978 16 10.7794 15.842 11.0607 15.5607C11.342 15.2794 11.5 14.8978 11.5 14.5V8.5H10.5Z"
                        fill="#666666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_419_1733">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </template>
              <template #append>
                <div>
                  <span v-if="timerEnabled" class="font-weight-medium txt-666 fs-12">Resend in {{ timerCount }}</span>
                  <v-btn v-if="resendText && !timerEnabled" @click="verifyEmail()" color="#0037B7" text plain x-small>
                    <span class="font-weight-medium fs-12 text-none">Resend OTP</span>
                  </v-btn>
                </div>
              </template>
            </v-text-field>
          </div>

          <div class="d-none d-md-flex">
            <v-btn v-if="!Otp_screen" height="48px" :loading="cardloader" :disabled="$v.email.$invalid || cardloader" @click="verifyEmail(), $v.email.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-14"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >
            <v-btn v-else height="48px" :loading="cardloader" :disabled="$v.otpemail.$invalid || cardloader || otpemail.length != 4" @click="otpVerifyemail(), $v.otpemail.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-4"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >
          </div>

          <v-footer padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-btn v-if="!Otp_screen" height="48px" :loading="cardloader" block :disabled="$v.email.$invalid || cardloader" @click="verifyEmail(), $v.email.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >
            <v-btn v-else height="48px" :loading="cardloader" block :disabled="$v.otpemail.$invalid || cardloader || otpemail.length != 4" @click="otpVerifyemail(), $v.otpemail.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
            >
          </v-footer>
        </div>
        <v-checkbox :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="alerts_notifications" color="#000">
          <template #label>
            <p class="subtitle-2 font-weight-regular txt-666 mb-0">
              I would like to receive the
              <v-menu open-on-hover>
                <template v-slot:activator="{on, attrs}">
                  <span v-bind="attrs" v-on="on" class="font-weight-black primary--text">alerts and notifications</span>
                </template>
                <v-card dark class="pa-3 rounded-lg caption" width="300"
                  >As a subscriber to the services offered by Zebu Share and Wealth Managements (P) Ltd.I hereby instruct Zebu Share and Wealth Managements (P) Ltd. To provide the following communications through the medium of short messages services and /or telephone calls on my registered phone
                  number(s) : (a) Research and investment ideas whether developed in-house and /or by outsourced agencies; (b) Market related alerts;(c) Offers or subscription to new products/services from time to time;(d) Account and trading related information and other notifications pursuant to
                  the services provided under the captioned agreement;(e) Information relating to investment products and services;(f) Any other services to aide in wealth creation process.</v-card
                >
              </v-menu>
              form ZEBU.
            </p>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/email.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
        <p class="font-weight-bold fs-18 white--text mt-2">Complete your Google Sign in with Google Accounts...</p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import jwt_decode from "jwt-decode";

import axios from "axios";
import api from "@/api";
import {validationMixin} from "vuelidate";
import {required, email, minLength} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    email: {required, email},
    otpemail: {required, minLength: minLength(4)},
  },
  data() {
    return {
      overlay: false,
      email: null,
      emailDisp: false,
      emailOtpDisp: false,
      otpemail: null,
      erroreOTPDisp: false,
      showpassword: false,
      Otp_screen: false,
      loader: false,

      resendText: false,
      timerCount: 60,
      timerEnabled: false,
      cardloader: false,
      errortext: "",
      resendloader: false,
      ash: false,
      typeBtn: true,
      typemannual: null,
      emailGoogle: "",
      encData: "",
      readydata: null,

      mobile: null,
      alerts_notifications: true,
    };
  },
  created() {
    //
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.app_status != "rejected" && p.stage == "email") {
          // axiosThis.$emit('clicked', 0)
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.cardloader = false;
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    googleSignIn: function () {
      this.overlay = true;
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      provider.setCustomParameters({
        prompt: "select_account",
      });
      let axiosThis = this;
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          if (result.user) {
            let responsePayload = jwt_decode(result.user.accessToken);
            const encryptedData = btoa(result.user.accessToken);
            axiosThis.encData = encryptedData;
            axiosThis.emailGoogle = responsePayload.email;
            if (responsePayload.email_verified == true) {
              if (responsePayload.email) {
                axiosThis.verifyEmail("google");
              }
            }
          }
          axiosThis.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.cardloader = false;
          axiosThis.typemannual = null;
          axiosThis.$emit("snack", 0, error);
          axiosThis.overlay = false;
        });
    },
    lowercase() {
      this.email = this.email.toLowerCase();
      this.email = this.email.replace(/\s/g, "");
    },
    OtpeValidation($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        // && keyCode !== 46  this if for dot key
        $event.preventDefault();
      }
      if (this.otpemail) {
        this.erroreOTPDisp = false;
        if (this.otpemail.length >= 4) {
          $event.preventDefault();
        }
      }
    },
    verifyEmail(data) {
      this.$v.$touch();
      this.ash = true;
      if (this.email && data != "google") {
        if (this.email.includes("@") && this.email.includes(".")) {
          var axiosThis = this;
          this.$v.$reset();
          this.cardloader = true;
          // var rmcode = sessionStorage.getItem('ref');
          // var rmcodee = null;
          // if (rmcode != null) {
          //     rmcodee = rmcode.toUpperCase() + '/reference';
          // }
          // else {
          //     rmcodee = '';
          // }
          var hash = new Date().toLocaleString();
          localStorage.setItem("sesslog", btoa(hash));
          var json_data = {
            mobile: atob(localStorage.getItem("hash")),
            email: axiosThis.email,
            // rmcode: rmcodee,
            hash: data ? data : "manual",
            alerts_notifications: axiosThis.alerts_notifications ? "Yes" : "No",
          };
          var config = {
            method: "post",
            url: api.api_url + "/getSignupNameEmailRMCode",
            headers: {"Content-Type": "application/json"},
            data: JSON.stringify(json_data),
          };
          axios(config)
            .then(function (response) {
              var data = response.data;
              if (data["data"] == "Client with this details already exists") {
                axiosThis.$emit("snack", 2, "Client with this details already exists.");
                axiosThis.email = "";
                axiosThis.timerEnabled = false;
              } else if (data["data"] == "This email already exist.") {
                axiosThis.$emit("snack", 2, "This email already exist.");
                axiosThis.email = "";
                axiosThis.timerEnabled = false;
              } else {
                axiosThis.$emit("snack", 1, "OTP sent to Email");
                axiosThis.Otp_screen = true;
                axiosThis.timerEnabled = true;
                axiosThis.erroreOTPDisp = false;
                setTimeout(() => axiosThis.$refs.emailotp.$refs.input.focus(), 100);
              }
              axiosThis.$v.$reset();
              axiosThis.cardloader = false;
            })
            .catch(function (error) {
              console.log(error);
              axiosThis.cardloader = false;
              axiosThis.$emit("snack", 0, error);
            });
          this.otpdisp = true;
          this.timerEnabled = false;
          this.timerCount = 60;
          this.emailDisp = false;
          this.emailOtpDisp = true;
        }
      } else if (data == "google") {
        this.$v.$reset();
        this.cardloader = true;
        // rmcode = sessionStorage.getItem('ref');
        // rmcodee = null;
        // if (rmcode != null) {
        //     rmcodee = rmcode.toUpperCase() + '/reference';
        // }
        // else {
        //     rmcodee = '';
        // }
        hash = new Date().toLocaleString();
        localStorage.setItem("sesslog", btoa(hash));
        json_data = {
          mobile: atob(localStorage.getItem("hash")),
          email: this.emailGoogle,
          // rmcode: rmcodee,
          hash: data ? data : "manual",
          alerts_notifications: this.alerts_notifications ? "Yes" : "No",
        };
        config = {
          method: "post",
          url: api.api_url + "/getSignupNameEmailRMCode",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        let axiosThis = this;
        axios(config)
          .then(function (response) {
            var data = response.data;
            if (data["data"] == "Client with this details already exists") {
              axiosThis.$emit("snack", 2, "Client with this details already exists.");

              axiosThis.Otp_screen = false;
              axiosThis.timerEnabled = false;
              axiosThis.$v.$reset();
              axiosThis.typemannual = null;
            } else if (data["data"] == "This email already exist.") {
              axiosThis.$emit("snack", 2, "This email already exist.");

              axiosThis.Otp_screen = false;
              axiosThis.timerEnabled = false;
              axiosThis.$v.$reset();
              axiosThis.typemannual = null;
            } else if (data["data"] == "initiated sucessfully") {
              axiosThis.$emit("snack", 1, "Initiated to Email");
              var json_data = {
                mobile: atob(localStorage.getItem("hash")),
                email: axiosThis.emailGoogle,
                type: "google",
                hash: axiosThis.encData,
              };
              var config = {
                method: "post",
                url: api.api_url + "/verifySignupEmail",
                headers: {"Content-Type": "application/json"},
                data: JSON.stringify(json_data),
              };
              axios(config)
                .then(function (response) {
                  var responseOTPemail = response.data;
                  if (responseOTPemail["data"] == "Email has been verified sucessfully") {
                    axiosThis.$emit("snack", 1, "Email is Verified");
                    axiosThis.$emit("clicked", "pan");
                    axiosThis.erroreOTPDisp = false;
                    axiosThis.$v.$reset();
                  } else {
                    axiosThis.otpemail = null;
                    axiosThis.errortext = "Invalid OTP";
                    axiosThis.erroreOTPDisp = true;
                  }
                  axiosThis.cardloader = false;
                })
                .catch(function (error) {
                  console.log(error);
                  axiosThis.cardloader = false;
                  axiosThis.$emit("snack", 0, error);
                });
            } else {
              axiosThis.$emit("snack", 1, "OTP sent to Email");
              axiosThis.Otp_screen = true;
              axiosThis.timerEnabled = true;
              axiosThis.$v.$reset();
            }
            axiosThis.cardloader = false;
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.cardloader = false;
            axiosThis.$emit("snack", 0, error);
          });
        this.otpdisp = true;
        this.timerEnabled = false;
        this.timerCount = 60;
        this.emailDisp = false;
        this.emailOtpDisp = true;
      }
    },
    otpVerifyemail() {
      this.$v.$touch();
      this.ash = true;
      if (this.otpemail) {
        if (this.otpemail.length == 4) {
          this.cardloader = true;
          this.$v.$reset();
          var axiosThis = this;
          var json_data = {
            mobile: atob(localStorage.getItem("hash")),
            email: axiosThis.email,
            otp: axiosThis.otpemail,
          };
          var config = {
            method: "post",
            url: api.api_url + "/verifySignupEmail",
            headers: {"Content-Type": "application/json"},
            data: JSON.stringify(json_data),
          };
          axios(config)
            .then(function (response) {
              var responseOTPemail = response.data;
              if (responseOTPemail["data"] != "Invalid OTP") {
                axiosThis.$emit("snack", 1, "Email is Verified");
                axiosThis.$emit("clicked", "pan");
                axiosThis.erroreOTPDisp = false;
                axiosThis.$v.$reset();
              } else {
                axiosThis.otpemail = null;
                axiosThis.errortext = "Invalid OTP";
                axiosThis.erroreOTPDisp = true;
              }
              axiosThis.cardloader = false;
            })
            .catch(function (error) {
              console.log(error);
              axiosThis.cardloader = false;
              axiosThis.$emit("snack", 0, error);
            });
        } else {
          this.erroreOTPDisp = false;
          this.cardloader = false;
        }
      } else {
        this.cardloader = false;
      }
    },
  },
  computed: {
    emailErrors() {
      if (this.email) {
        if (!this.ash) {
          this.$v.$reset();
        }
      }
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("E-mail is not valid");
      !this.$v.email.required && errors.push("Mandatory Field");
      return errors;
    },
    eotpErrors() {
      if (this.otpemail) {
        if (this.otpemail.length != 4 && !this.ash) {
          this.$v.$reset();
        }
      }
      const errors = [];
      if (this.erroreOTPDisp) {
        errors.push("You have entered the wrong OTP");
        return errors;
      } else {
        if (!this.$v.otpemail.$dirty) return errors;
        !this.$v.otpemail.required && errors.push("Mandatory Field");
        !this.$v.otpemail.minLength && errors.push("It should be 4 digit OTP");
        return errors;
      }
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false;
          this.resendText = true;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        if (response.data.email) {
          axiosThis.readydata = true;
          axiosThis.email = response.data.email;
          axiosThis.mobile = response.data.mobile_no;
        } else {
          axiosThis.readydata = false;
          axiosThis.email = "";
          axiosThis.mobile = "";
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.cardloader = false;
        axiosThis.$emit("snack", 0, error);
      });

    // this.$emit('clicked', 0)
  },
};
</script>
