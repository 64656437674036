<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/personal" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 4 of 6
        </p>
        <p class="headline font-weight-black mb-2">{{ bnkd == null ? "Link your Bank Account" : bnkd == 0 ? "Enter your Bank Details" : "Your Bank Details" }}</p>
        <p class="subtitle-1 font-weight-regular txt-666">{{ bnkd == 0 ? " Add your savings or current account to ensure secure and hassle-free fund transfers for investing and trading." : "To unlock seamless transactions. Your details are safe and secure—we’re just making sure everything matches!" }}</p>

        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.bank == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Bank have been rejected due <b>{{ rejectdata.bank_reject_reason }}</b></span
          >
        </v-alert>
        <div v-if="!dataloader">
          <div v-if="bnkd == null && !cardloader" class="pt-4">
            <div v-if="linktype == 'unman' && qrimage != 'mobile'" class="text-center">
              <p class="fs-14 mb-0">Scan QR code with any UPI app</p>
              <img width="60%" src="@/assets/upis.svg" />
              <v-card class="elevation-0 px-5 pb-2">
                <img width="50%" :src="qrimage" />
              </v-card>
              <p class="black--text fs-12 mb-2">Pay <b>₹1.00</b></p>
              <v-btn height="48px" @click="(qrimage = null), (linktype = ''), (bnkd = null), updRevpd()" color="#444" outlined style="border: 2px solid #444 !important" small class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Cancel Transaction</span></v-btn>
            </div>
            <div v-else-if="qrimage == 'mobile'">
              <v-card width="100%" class="elevation-0 px-5 text-center py-8 mx-auto rounded-lg" outlined>
                <v-card width="40%" class="elevation-0 mx-auto">
                  <v-progress-linear color="#000" buffer-value="0" class="mb-5" reverse stream value="0"></v-progress-linear>
                </v-card>
                <p class="font-weight-bold title">Awaiting UPI confirmation</p>
                <p class="caption txt-666 mb-8">This will take a few seconds.</p>
                <v-btn height="48px" @click="(qrimage = null), (linktype = ''), (bnkd = null), updRevpd()" color="#444" outlined style="border: 2px solid #444 !important" small class="text-none rounded-pill elevation-0"
                  ><span class="subtitle-1 font-weight-medium px-6">Cancel Transaction</span></v-btn
                >
              </v-card>
            </div>
            <div v-else>
              <v-card :disabled="snackbar && toasttext.includes('Try another way')" @click="(linktype = 'unman'), setRevpd()" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg mb-6">
                <v-row no-glutters>
                  <v-col cols="10" sm="11">
                    <p class="font-weight-medium fs-18 mb-2">Verify with any UPI ID <v-chip color="#66BB6A" text-color="#fff" class="rounded-md px-1 ml-1" x-small label>Recommended</v-chip></p>
                    <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px"> Let’s make this quick! We’ll debit ₹1 (and refund it later) to confirm your account.</p>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <v-radio-group :disabled="snackbar && toasttext.includes('Try another way')" v-model="linktype" column>
                      <v-radio class="ml-auto" @click="(linktype = 'unman'), setRevpd()" label="" color="#000000" value="unman"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>

              <v-card @click="(bnkd = 0), (linktype = 'man')" style="border: 1px solid #ddd !important" color="transparent" class="pa-4 elevation-0 rounded-lg">
                <v-row no-glutters>
                  <v-col cols="10" sm="11">
                    <p class="font-weight-medium fs-18 mb-2">Add Details Manually</p>
                    <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px"> Prefer the manual route? Enter your account number and IFSC code to get started.</p>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <v-radio-group v-model="linktype" column>
                      <v-radio class="ml-auto" @click="(bnkd = 0), (linktype = 'man')" label="" color="#000000" value="man"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
          <div v-else-if="bnkd == 0 && !cardloader" class="pt-4">
            <p class="font-weight-medium fs-17 mb-0">Select account type</p>
            <v-chip-group column>
              <v-chip
                :disabled="retrybank == false && bankverifystatus == true"
                @click="accountType = tag"
                @keyup.enter="bankDetailUpdate()"
                :color="accountType == tag ? '#000' : '#666666'"
                :text-color="accountType == tag ? '#fff' : '#666666'"
                v-for="tag in accountTypeItems"
                :outlined="accountType != tag"
                :key="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">Enter your account number</p>
            <v-text-field :disabled="retrybank == false && bankverifystatus == true" class="txt-field mb-3" @keyup.enter="bankDetailUpdate()" hide-spin-buttons type="number" v-model="accNo" required @keypress="ash = false" :error-messages="accErrors" @blur="$v.accNo.$touch()">
              <template #prepend-inner>
                <div class="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_554_7145)">
                      <path d="M5 8.5V15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 8.5V15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11 8.5V15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M14 8.5V15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M2 8.5V15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M0.5 15.5H15.5" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M0.5 6.5V3.5L8 0.5L15.5 3.5V6.5H0.5Z" stroke="#212121" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5Z" fill="#212121" />
                    </g>
                    <defs>
                      <clipPath id="clip0_554_7145">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </template>
            </v-text-field>

            <p class="font-weight-medium fs-17 mb-0 mt-4 mt-md-6">Enter IFSC code</p>
            <v-text-field
              :disabled="retrybank == false && bankverifystatus == true"
              class="txt-field mb-7 mb-md-3"
              validate-on-blur
              v-model="ifsc"
              oninput="this.value = this.value.toUpperCase()"
              @keyup.enter="bankDetailUpdate()"
              @keyup="ash = false"
              v-on:keyup="ifscValidation()"
              :error-messages="ifscErrors"
              @blur="$v.ifsc.$touch()"
              @input="ifscFetchDetails"
              counter="11"
              required
            >
              <template #prepend-inner>
                <div class="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_565_7212)">
                      <path d="M10.5 0.5L12.5 2.5L7.5 7.5L4.5 8.5L5.5 5.5L10.5 0.5Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.5 6.5H15.5V15.5H0.5V6.5H2.5" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M3.5 11.5H12.5" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_565_7212">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </template>
              <template #append>
                <div v-if="bank_name && !bankverifystatus">
                  <v-tooltip top color="#000">
                    <template v-slot:activator="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on" size="16" color="#37A668">mdi-checkbox-marked-circle-outline</v-icon>
                    </template>
                    <div class="py-4">
                      <h2 class="ml-2" style="color: #fff; font-size: 16px">Bank Details</h2>
                      <strong style="padding-left: 8px"> {{ bank_name }} </strong>
                      <p class="mb-0" style="font-size: 12px; padding-left: 8px">{{ bank_branch }} | {{ bank_micr }}</p>
                    </div>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>

            <v-alert v-if="errorMessage" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
              <span class="subtitle-2 font-weight-regular">{{ errorMessage }}</span>
            </v-alert>

            <div class="pt-6 d-none d-md-flex">
              <v-btn height="48px" @click="changeBank()" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0 mr-4"><span class="subtitle-1 font-weight-medium px-6">Change</span></v-btn>

              <v-btn v-if="!bankverifystatus" @click="bankDetailUpdate()" height="48px" :loading="cardloader" color="#000" :disabled="(accountType != 'Saving' && accountType != 'Current') || $v.$invalid || cardloader" class="white--text text-none rounded-pill elevation-0"
                ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
              >

              <v-btn :disabled="cardloader" v-else @click="checkforbank()" height="48px" color="#000" class="white--text text-none rounded-pill elevation-0"
                ><span class="white--text subtitle-1 font-weight-medium px-6">{{ retrybank ? "Retry" : "Continue" }}</span></v-btn
              >
            </div>
            <p class="subtitle-2 font-weight-regular txt-666 mt-6">Your account details are safe and protected</p>
          </div>
          <div v-else-if="bnkd == 1 && !cardloader">
            <v-card width="100%" class="text-center pt-8 elevation-0 mb-16 mb-md-0" color="transparent">
              <img v-if="!retrybank" src="@/assets/bankverifi.svg" alt="bankverifi" />
              <img v-else src="@/assets/bankunverifi.svg" alt="bankunverifi" />
              <p class="fs-18 font-weight-medium">Bank Account {{ retrybank ? "Not verified" : "Verified! You’re all set to trade with ease." }}</p>

              <v-card style="border: 1px solid #ebeef0 !important" color="rgba(246, 247, 247, 0.5)" class="px-6 py-3 text-left mx-auto elevation-0 rounded-xl bank-d">
                <v-list-item class="pl-0">
                  <img v-if="banklogo != 'n'" width="38px" :src="`${banklogo}/banklogo?bank=${ifsc.slice(0, 4).toLowerCase()}&type=svg`" class="mr-4 pa-1" />
                  <v-list-item-avatar v-else color="#fff" size="40">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M5 9.00002V17M9.5 9.00002V17M14.5 9.00002V17M19 9.00002V17M3 18.6L3 19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7952 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7952 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.04 21 17.7599 20.891 17.546C20.7951 17.3579 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3579 3.10899 17.546C3 17.7599 3 18.04 3 18.6ZM11.6529 3.07715L4.25291 4.7216C3.80585 4.82094 3.58232 4.87062 3.41546 4.99082C3.26829 5.09685 3.15273 5.24092 3.08115 5.40759C3 5.59654 3 5.82553 3 6.28349L3 7.40002C3 7.96007 3 8.2401 3.10899 8.45401C3.20487 8.64217 3.35785 8.79515 3.54601 8.89103C3.75992 9.00002 4.03995 9.00002 4.6 9.00002H19.4C19.9601 9.00002 20.2401 9.00002 20.454 8.89103C20.6422 8.79515 20.7951 8.64217 20.891 8.45401C21 8.2401 21 7.96007 21 7.40002V6.2835C21 5.82553 21 5.59655 20.9188 5.40759C20.8473 5.24092 20.7317 5.09685 20.5845 4.99082C20.4177 4.87062 20.1942 4.82094 19.7471 4.7216L12.3471 3.07715C12.2176 3.04837 12.1528 3.03398 12.0874 3.02824C12.0292 3.02314 11.9708 3.02314 11.9126 3.02824C11.8472 3.03398 11.7824 3.04837 11.6529 3.07715Z"
                        stroke="black"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ bankname }}</v-list-item-title>
                    <v-list-item-subtitle>A/C No: {{ accNo }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-row no-glutters>
                  <v-col cols="3">
                    <p class="font-weight-regular txt-666 subtitle-2 mb-2">
                      IFSC Code <br />
                      <br />
                      Branch <br />
                      <br />
                      Address
                    </p>
                  </v-col>
                  <v-col cols="9">
                    <p class="font-weight-medium subtitle-2 mb-2" style="color: #121212 !important">
                      {{ ifsc }} <br />
                      <br />
                      {{ ifscdata.CITY }} <br />
                      <br />
                      <span class="text-lowercase">{{ ifscdata.ADDRESS }}, {{ ifscdata.DISTRICT }}, {{ ifscdata.STATE }}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>

              <v-alert v-if="bankproof == false" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-5 mx-auto rounded-xl text-left py-2 bank-d" color="warning" elevation="0">
                <p class="font-weight-bold mb-1">Name on PAN & Bank A/C did not match</p>
               
                <v-row no-glutters class="pt-2">
                  <v-col cols="5" class="pr-0">
                    <p class="font-weight-regular txt-666 subtitle-2 mb-2">Name on PAN</p>
                    <p class="font-weight-regular txt-666 subtitle-2 mb-2">Name on A/C</p>
                  </v-col>
                  <v-col cols="7" class="pl-0">
                    <p class="font-weight-medium subtitle-2 mb-2" style="color: #121212 !important">
                      {{ panname.Name ? panname.Name : "-" }}
                    </p>
                    <p class="font-weight-medium subtitle-2 mb-2" style="color: #e65100 !important">
                      {{ checkname ? checkname.registered_name : "-" }}
                    </p>
                  </v-col>
                </v-row>
                <p class="caption mb-0" style="line-height: 16px !important">Upload a bank proof where the name matches the one on your PAN card.</p>
              </v-alert>

              <div class="d-none d-md-inline-flex">
                <v-btn height="48px" @click="changeBank()" color="#444" outlined style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0 my-6 mr-4"><span class="subtitle-1 font-weight-medium px-6">Change</span></v-btn>
                <v-btn height="48px" color="#000" @click="retrybank ? ((bnkd = 0), (linktype = 'man')) : toNext()" class="white--text text-none rounded-pill elevation-0 my-6"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">{{ retrybank ? "Retry" : "Continue" }}</span></v-btn
                >
              </div>
            </v-card>
          </div>
          <div v-else-if="cardloader">
            <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
              <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
              <p class="font-weight-bold mt-2">{{ linktype == "unman" ? "Proceeding to reverse penny drop..." : "Don't press back or close while fetching Your Bank Details..." }}</p>
            </v-card>
          </div>
          <v-footer v-if="bnkd == 0 && !cardloader" padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-row>
              <v-col cols="6">
                <v-btn height="48px" @click="changeBank()" color="#444" outlined block style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Change</span></v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn v-if="!bankverifystatus" @click="bankDetailUpdate()" height="48px" :loading="cardloader" color="#000" block :disabled="(accountType != 'Saving' && accountType != 'Current') || $v.$invalid || cardloader" class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
                >

                <v-btn :disabled="cardloader" v-else @click="checkforbank()" height="48px" color="#000" block class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">{{ retrybank ? "Retry" : "Continue" }}</span></v-btn
                >
              </v-col>
            </v-row>
          </v-footer>
          <v-footer v-if="bnkd == 1 && !cardloader" padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-row>
              <v-col cols="6">
                <v-btn height="48px" @click="changeBank()" color="#444" outlined block style="border: 2px solid #444 !important" class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">Change</span></v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn height="48px" color="#000" @click="retrybank ? ((bnkd = 0), (linktype = 'man')) : toNext()" block class="white--text text-none rounded-pill elevation-0"
                  ><span class="white--text subtitle-1 font-weight-medium px-6">{{ retrybank ? "Retry" : "Continue" }}</span></v-btn
                >
              </v-col>
            </v-row>
          </v-footer>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img v-if="bnkd == null" src="@/assets/bnk1.svg" class="rgt-img" alt="rgt-img" />
          <img v-if="bnkd == 0" src="@/assets/bnk2.svg" class="rgt-img" alt="rgt-img" />
          <img v-if="bnkd == 1" src="@/assets/bnk3.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
import {validationMixin} from "vuelidate";
import {required, minLength} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    ifsc: {required},
    accNo: {required, minLength: minLength(8)},
  },
  data: () => ({
    toasttext: null,
    snackbar: false,

    name: "",
    accountTypeItems: ["Saving", "Current"],
    accountType: "",

    bnkd: null,
    linktype: "",

    bankdata: false,
    accNo: null,
    ifsc: null,
    bank_name: null,
    bank_micr: null,
    bank_branch: null,
    cardloader: false,
    ifscErrorDesc: false,
    ash: false,
    retryCount: 0,
    errorMessage: "",
    retrybankacc: false,
    bankverifystatusaftRetry: false,
    bankverifystatus: false,
    retrybank: false,
    bankUname: null,
    bankname: null,
    accstatus: null,
    tran_no: null,
    ifscdata: [],
    banklogo: null,
    panname: [],
    checkname: "",
    bankproof: null,
    dataloader: null,
    rejectdata: [],

    qrimage: null,
    timerCount: 0,
    timerEnabled: false,
  }),
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.stage_wise_approval.bank != "yes" && (p.stage == "bank" || p.stage == "bankproof" || p.stage == "segment" || p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 4, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.bank == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
          axiosThis.bankproof = response.data.data_type.bankproof;
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    toNext() {
      this.$emit("changed", 0);
      if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.bank == "no") {
        this.$emit("steged", "bank");
      } else {
        this.$emit("clicked", this.bankproof ? "segment" : "bankproof");
      }
    },
    changeBank() {
      this.$emit("changed", 4);
      this.bankverifystatus = false;
      this.bank_name = null;
      this.bank_branch = null;
      this.bank_micr = "";
      this.ifscErrorDesc = true;
      this.ifscdata = "";
      this.accNo = "";
      this.ifsc = "";
      this.retrybank = false;
      this.accountType = "";
      this.ifscFetchDetails();
      this.bnkd = null;
      this.linktype = "";
    },
    checkforbank() {
      if (this.retrybank) {
        this.bankDetailUpdate();
      } else {
        this.toNext();
      }
      if (!this.bank_name) {
        this.ifscFetchDetails();
      }
    },
    ifscValidation() {
      if (this.ifsc) {
        var newVal = this.ifsc.replace("-", "");
        if (this.ifsc.length > 11) {
          this.ifsc = newVal.slice(0, 11);
        }
      }
    },
    bankDetailUpdate() {
      this.$v.$touch();
      this.ash = true;
      if (this.ifsc && this.accNo && this.bank_name && (this.accountType == "Saving" || this.accountType == "Current")) {
        this.cardloader = true;
        this.$v.$reset();
        let type = this.accountType == "Saving" ? "Saving Account" : "Current Account";
        var config = {
          method: "post",
          url: api.api_url + "/bankDetails?mob=" + atob(localStorage.getItem("hash")) + "&bankName=" + this.bank_name + "&ifsc=" + this.ifsc + "&accountNo=" + this.accNo + "&micr=" + this.bank_micr + "&branch=" + this.bank_branch + "&bank_account_type=" + type,
          headers: {},
        };

        var axiosThis = this;
        axios(config)
          .then(function (response) {
            var resdata = response.data;
            if (resdata != "error occured") {
              if (response.data && resdata["data"]["status"] == "completed") {
                axiosThis.bankname = resdata["data"]["fund_account"]["bank_account"]["bank_name"];
                axiosThis.bankUname = resdata["data"]["results"]["registered_name"];
                axiosThis.errorMessage = "Verified successfully";
                axiosThis.accstatus = resdata["data"]["results"]["account_status"];
                axiosThis.tran_no = resdata["data"]["utr"];
                axiosThis.accNo = resdata["data"]["fund_account"]["bank_account"]["account_number"];
                axiosThis.ifsc = resdata["data"]["fund_account"]["bank_account"]["ifsc"];
                axiosThis.checkname = resdata.data.results;
                axiosThis.bankverifystatus = true;
                axiosThis.getbankLogo();
                axiosThis.getNamecheck();

                if (axiosThis.accstatus != "active") {
                  var localHash = localStorage.getItem("hash");
                  var retryCountFromLocal = JSON.parse(localStorage.getItem(localHash));
                  axiosThis.retryCount = retryCountFromLocal;
                  axiosThis.retryCount += 1;
                  localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                  axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time";
                  if (axiosThis.retryCount >= 3) {
                    axiosThis.retrybank = false;
                    axiosThis.bankverifystatus = true;
                    axiosThis.retrybankacc = false;
                    axiosThis.retryCount = 3;
                    localStorage.setItem(localHash, "3");
                    axiosThis.errorMessage = "We are unable to validate your banking information. Please provide accurate bank information in the next stage. Your information will be verified by our team.";
                  } else {
                    axiosThis.retrybank = true;
                    axiosThis.bankverifystatus = true;
                  }
                }
                axiosThis.bnkd = 1;
              } else {
                axiosThis.accstatus = resdata["data"]["results"]["account_status"];
                axiosThis.tran_no = resdata["data"]["utr"];
                localHash = localStorage.getItem("hash");
                retryCountFromLocal = JSON.parse(localStorage.getItem(localHash));
                axiosThis.retryCount = retryCountFromLocal;
                axiosThis.retryCount += 1;
                localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time";
                if (axiosThis.retryCount >= 3) {
                  axiosThis.retrybank = false;
                  axiosThis.bankverifystatus = true;
                  axiosThis.retrybankacc = false;
                  axiosThis.retryCount = 3;
                  localStorage.setItem(localHash, "3");
                  axiosThis.errorMessage = "We are unable to validate your banking information. Please provide accurate bank information in the next stage. Your information will be verified by our team.";
                } else {
                  axiosThis.retrybank = true;
                  axiosThis.bankverifystatus = true;
                }
                axiosThis.bnkd = 0;
              }
            } else {
              axiosThis.$emit("snack", 2, resdata);
            }
            axiosThis.cardloader = false;
            axiosThis.ifscFetchDetails();
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
          });
      } else if ((this.accountType != "Saving" && this.accountType != "Current") || this.$v.$invalid) {
        this.$emit("snack", 2, this.accountType != "Saving" && this.accountType != "Current" ? "Select your account type" : this.$v.$invalid ? "Check enter valid account no and IFSC code " : "Check all fileds are filled!");
      }
    },
    getNamecheck() {
      var json_data = {
        mobile: atob(localStorage.getItem("hash")),
      };
      var config = {
        method: "post",
        url: api.api_url + "/getSignupPAN",
        headers: {"Content-Type": "application/json"},
        data: JSON.stringify(json_data),
      };
      let axiosThis = this;
      axios(config)
        .then(function (response) {
          if (response.data.Name) {
            axiosThis.panname = response.data;
            axiosThis.setBankproof();
          }
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    setBankproof() {
      let na = this.checkname.registered_name ? this.checkname.registered_name.trim() : "null";
      let pn = this.panname.Name ? this.panname.Name.trim() : "null";
      let data = JSON.stringify({
        bankproof: na.toUpperCase().replace("  ", " ") == pn.toUpperCase().replace("  ", " ") ? true : false,
      });
      let config = {
        method: "post",
        url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data.data_type) {
            axiosThis.bankproof = response.data.data_type.bankproof;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    getbankLogo() {
      let config = {
        method: "get",
        url: `${api.api_url}/banklogo?bank=${this.ifsc.slice(0, 4).toLowerCase()}&type=svg`,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data != "none") {
            axiosThis.banklogo = api.api_url;
          } else {
            axiosThis.banklogo = "n";
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    async ifscFetchDetails() {
      if (this.ifsc.length >= 11) {
        var ifsvc = this.ifsc;
        if (this.ifsc.length > 11) {
          ifsvc = this.ifsc.slice(0, 11);
          this.$v.$reset();
        }
        var config = {
          method: "get",
          url: "https://ifsc.razorpay.com/" + ifsvc,
        };
        var axiosThis = this;
        await axios(config)
          .then(function (response) {
            if (response.data) {
              var req_data = response.data;
              var bank_name = req_data["BANK"];
              var micr = req_data["MICR"];
              var branch = req_data["BRANCH"] + ", " + req_data["CITY"] + ", " + req_data["STATE"]; //+', '+pincode
              axiosThis.bank_name = bank_name;
              axiosThis.bank_branch = branch;
              axiosThis.bank_micr = micr;
              axiosThis.ifscErrorDesc = false;
              axiosThis.cardloader = false;
              axiosThis.ifscdata = req_data;
              axiosThis.$v.$reset();
            }
          })
          .catch(function (error) {
            console.log("IFSC errorr catched!" + error);
            axiosThis.ifscErrorDesc = true;
            axiosThis.ifscErrors;
          });
      } else {
        this.bank_name = null;
        this.bank_branch = null;
        this.bank_micr = null;
      }
    },
    setRevpd() {
      this.cardloader = true;
      let nav;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        nav = "mobile";
      } else {
        nav = "other";
      }
      let config = {
        method: "post",
        url: api.api_url + "/create_rpd?mobile=" + atob(localStorage.getItem("hash")) + `&view=${nav}`,
        headers: {},
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data && !response.data.msg && (response.data.upi || response.data.includes("�PNG"))) {
            if (nav == "mobile" && response.data.upi) {
              axiosThis.qrimage = nav;
              window.location.href = response.data.upi;
            } else if (response.data.includes("�PNG") && typeof response.data == "string") {
              axiosThis.qrimage = api.api_url + "/create_rpd?mobile=" + atob(localStorage.getItem("hash")) + `&view=${nav}`;
            }
            if (response.data.upi || response.data.includes("�PNG")) {
              setTimeout(() => {
                axiosThis.updRevpd();
              }, 6000);
              axiosThis.timerCount = 600;
              axiosThis.timerEnabled = true;
            }
          } else {
            if (response.data && response.data.msg.includes("Try another way")) {
              setTimeout(() => {
                axiosThis.bnkd = 0;
                axiosThis.linktype = "man";
              }, 1600);
            } else {
              axiosThis.bnkd = null;
            }
            axiosThis.qrimage = null;
            axiosThis.linktype = "";
            axiosThis.snackbar = true;
            axiosThis.toasttext = response.data.msg ? response.data.msg : response.data;
            axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
          }
          axiosThis.cardloader = false;
          axiosThis.bnkd = null;
        })
        .catch((error) => {
          console.log(error);
          axiosThis.cardloader = false;
          axiosThis.qrimage = null;
          axiosThis.bnkd = null;
          axiosThis.linktype = "";
          axiosThis.$emit("snack", 0, error);
        });
    },
    updRevpd() {
      if (this.qrimage != null) {
        let config = {
          method: "post",
          url: api.api_url + "/check_rpd?mobile=" + atob(localStorage.getItem("hash")),
          headers: {},
        };

        let axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            if (response.data && response.data.status == "BAV_REVERSE_PENNY_DROP_CREATED" && axiosThis.timerEnabled != 0) {
              setTimeout(() => {
                if (axiosThis.qrimage != null) {
                  axiosThis.updRevpd();
                }
              }, 3600);
            } else if (response.data && response.data.status == "BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL") {
              window.location.reload();
            } else {
              axiosThis.putRPD(response.data);
            }
          })
          .catch((error) => {
            console.log(error);
            axiosThis.putRPD(error, "e");
          });
      } else {
        if (!this.snackbar) {
          this.putRPD();
        }
      }
    },
    putRPD(error, e) {
      this.$emit("snack", e ? 0 : 2, !this.qrimage ? "Reverse penny drop as canceled" : this.timerEnabled == 0 ? "Transaction timeout: try again" : error);
      this.qrimage = null;
      this.bnkd = null;
      this.linktype = "";
      this.timerEnabled = false;
    },
  },
  computed: {
    ifscErrors() {
      const errors = [];
      if (this.ifsc) {
        if (this.ifsc.length != 11 && !this.ash) {
          this.$v.$reset();
        }
      }
      if (this.ifsc) {
        if (this.ifsc.length != 11) {
          errors.push("Please enter 11 Digit IFSC Code");
        }
      }
      if (this.ifscErrorDesc) {
        errors.push("Oops! The IFSC code you entered seems incorrect.");
        return errors;
      } else {
        if (!this.$v.ifsc.$dirty) return errors;
        !this.$v.ifsc.required && errors.push("IFSC code required");
        return errors;
      }
    },
    accErrors() {
      const errors = [];
      if (!this.ash) {
        this.$v.$reset();
      }
      if (!this.$v.accNo.$dirty) return errors;
      !this.$v.accNo.minLength && errors.push("invalid number");
      !this.$v.accNo.required && errors.push("Account number required");
      return errors;
    },
    rules() {
      if (this.exchangeSelect.length == 0) {
        return [false];
      } else {
        return [true];
      }
    },
  },
  mounted() {
    this.dataloader = true;
    var config = {
      method: "post",
      url: api.api_url + "/bankDetails?mob=" + atob(localStorage.getItem("hash")),
      headers: {},
    };
    var axiosThis = this;
    axios(config)
      .then(function (response) {
        axiosThis.cardloader = false;
        axiosThis.dataloader = false;
        var resdata = response.data;
        axiosThis.bankverifystatus = false;
        if (resdata.data && resdata["response"] == "yes" && ((resdata["data"] && resdata["data"]["fund_account"] && resdata["data"]["results"]) || (resdata.data.status == "BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL" && resdata["data"]["data"]))) {
          if (resdata.data.status == "BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL") {
            axiosThis.bankname = resdata["data"]["data"]["ifscDetails"]["name"];
            axiosThis.bankUname = resdata["data"]["data"]["bankAccountName"];
            axiosThis.accstatus = resdata["data"]["data"]["ifscDetails"]["upi"] == "true" ? true : resdata["data"]["data"]["ifscDetails"]["upi"];
            axiosThis.tran_no = null;
            axiosThis.accNo = resdata["data"]["data"]["bankAccountNumber"];
            axiosThis.ifsc = resdata["data"]["data"]["bankAccountIfsc"];
            axiosThis.checkname = {
              account_status: "active",
              registered_name: resdata["data"]["data"]["bankAccountName"],
            };
          } else if (resdata["data"] && resdata["data"]["fund_account"]) {
            axiosThis.bankname = resdata["data"]["fund_account"]["bank_account"]["bank_name"];
            axiosThis.bankUname = resdata["data"]["results"]["registered_name"];
            axiosThis.accstatus = resdata["data"]["results"]["account_status"];
            axiosThis.tran_no = resdata["data"]["utr"];
            axiosThis.accNo = resdata["data"]["fund_account"]["bank_account"]["account_number"];
            axiosThis.ifsc = resdata["data"]["fund_account"]["bank_account"]["ifsc"];
            axiosThis.checkname = resdata.data.results;
          }
          axiosThis.bankverifystatus = true;
          axiosThis.bnkd = 1;
          if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.bank == "no") {
            axiosThis.changeBank();
          } else {
            axiosThis.getbankLogo();
            axiosThis.getNamecheck();
          }
        } else {
          axiosThis.bnkd = null;
          axiosThis.linktype = "";
          axiosThis.bankname = resdata["data"]["fund_account"]["bank_account"]["bank_name"];
          axiosThis.bankUname = resdata["data"]["results"]["registered_name"];
          axiosThis.accstatus = resdata["data"]["results"]["account_status"];
          axiosThis.tran_no = resdata["data"]["utr"];
          axiosThis.accNo = resdata["data"]["fund_account"]["bank_account"]["account_number"];
          axiosThis.ifsc = resdata["data"]["fund_account"]["bank_account"]["ifsc"];
          axiosThis.checkname = resdata.data.results;
          var localHash = localStorage.getItem("hash");
          var retryCountFromLocal = JSON.parse(localStorage.getItem(localHash));
          axiosThis.retryCount = retryCountFromLocal;
          if (axiosThis.retryCount >= 3) {
            axiosThis.retrybank = false;
            axiosThis.bankverifystatus = true;
            axiosThis.retrybankacc = false;
            axiosThis.retryCount = 3;
            localStorage.setItem(localHash, "3");
            axiosThis.errorMessage = "We are unable to validate your banking information. Please provide accurate bank information in the next stage. Your information will be verified by our team.";
            axiosThis.bnkd = 0;
            axiosThis.linktype = "man";
          } else {
            axiosThis.retrybank = true;
            axiosThis.bankverifystatus = true;
          }
        }
        axiosThis.ifscFetchDetails();
      })
      .catch(function (error) {
        console.log(error);
        axiosThis.$emit(error != "TypeError: Cannot read properties of null (reading 'fund_account')" && error != "TypeError: Cannot read properties of undefined (reading 'bank_account')" && error != "TypeError: Cannot read properties of undefined (reading 'ifscDetails')" ? "snack" : "", 0, error);
      });
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
