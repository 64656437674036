<template>
  <div>
    <div :class="acceptannexueScreen ? 'd-flex' : 'd-none'" class="pos-rlt">
      <v-row no-glutters class="body-rows-pdf">
        <v-col cols="12" class="pa-0 left-cols-pdf" @scroll.passive="handleScroll">
          <v-card id="pdfpageheight" class="elevation-0" width="`100%">
            <div class="text-center" v-if="overlaypdf" style="position: fixed; top: 50%; left: 50%">
              <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
            </div>
            <pdf v-for="i in numPages" :id="`pdf${i}`" :key="i" :page="i" @page-loaded="$event == numPages ? (overlaypdf = true) : (overlaypdf = true)" :src="src"> </pdf>
          </v-card>
        </v-col>
      </v-row>
      <v-footer absolute style="bottom: -56px !important" color="transparent">
        <v-row no-glutters>
          <v-col cols="6" md="3" class="ml-md-auto">
            <v-btn style="border: 2px solid #444 !important; background-color: #fff" outlined height="48px" @click="(acceptannexueScreen = false), (mdt = false)" color="#000" block class="text-none rounded-pill elevation-0"><span class="subtitle-1 font-weight-medium px-6">back</span></v-btn>
          </v-col>
          <v-col cols="6" md="3">
            <v-btn :disabled="isScrolledtoBottom" height="48px" block color="#000" class="white--text text-none rounded-pill elevation-0" @click="acceptannexueScreen = false"><span class="white--text subtitle-1 font-weight-medium px-6">Accept</span></v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </div>

    <v-row :class="!acceptannexueScreen ? 'd-flex' : 'd-none'" no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" @click="setUpdate('selfie')" to="/selfie" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 6 of 6
        </p>
        <p class="headline font-weight-black mb-2">Final step, let’s lock it in!</p>
        <p class="subtitle-1 font-weight-regular txt-666">Use Aadhaar e-Sign to verify your account and take the first step toward smarter trading and investing.</p>
        <div v-if="!dataloader">
          <div>
            <div class="mb-16 mb-md-0">
              <div class="pt-4" v-if="ps != 400">
                <v-skeleton-loader v-if="fileload" height="200px" max-width="180" type="card"></v-skeleton-loader>

                <img v-else src="@/assets/fin-form.svg" height="200px" alt="" />
                <p class="font-weight-bold fs-17 mt-4">Mandatory Declarations</p>

                <v-checkbox
                  hide-details
                  v-model="mdo"
                  color="#000"
                  label="I confirm all details shared during onboarding are accurate to the best of my knowledge."
                ></v-checkbox>
                <v-checkbox hide-details v-model="mdr" color="#000" label="I certify that my annual income and net worth are up-to-date (within the past year)."></v-checkbox>
                <v-checkbox @click="onCheckboxClicked($event)" hide-details v-model="mdt" color="#000" label="I understand that, as per the regulations, nominations for account are accepted only in physical form and I will add nominees to my account later.">
                  <template #label>
                    <span>
                      <!-- I confirm to have and understood the contents of and
                                            <a class="mailto-txt"
                                                href="https://zebuetrade.com/static/Downloads/download_docs/kyc/trading-disclosure-document.pdf"
                                                target="_blank">Equity Annexure</a>
                                            <a class="mailto-txt"
                                                href="https://zebuetrade.com/static/Downloads/download_docs/kyc/COM_Disclosure.pdf"
                                                target="_blank">Commodity Annexure </a>
                                            documents -->
                      I have read and understood the content of all mandatory documents.
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div v-else>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.mobile == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Mobile have been rejected due <b>{{ rejectdata.mobile_reject_reason }}</b></span
                  >
                  <v-btn height="16px" to="/" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.email == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Email have been rejected due <b>{{ rejectdata.email_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('email')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.pan == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your PAN have been rejected due <b>{{ rejectdata.PAN_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('pan')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.panproof == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your PAN proof have been rejected due <b>{{ rejectdata.PAN_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('panupload')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.digilocker == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Digilocker have been rejected due <b>{{ rejectdata.digilocker_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('verification')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.personal == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Personal have been rejected due <b>{{ rejectdata.personal_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('personal')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.bank == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Bank have been rejected due <b>{{ rejectdata.bank_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('bank')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.bankproof == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Bank proof have been rejected due <b>{{ rejectdata.bank_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('bankproof')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.segment == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Segment have been rejected due <b>{{ rejectdata.finan_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('segment')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.signature == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Signature have been rejected due <b>{{ rejectdata.sign_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('signature')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.selfie == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Selfie have been rejected due <b>{{ rejectdata.image_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('selfie')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
                <v-alert v-if="rejectdata.stage_wise_approval ? rejectdata.stage_wise_approval.nominee == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
                  <span class="subtitle-2 font-weight-regular"
                    >Your Nominee have been rejected due <b>{{ rejectdata.nominee_reject_reason }}</b></span
                  >
                  <v-btn height="16px" @click="setUpdate('nominee')" dense class="text-none font-weight-bold pl-2" text plain color="primary">Update</v-btn>
                </v-alert>
              </div>
              <div class="d-none d-md-inline-flex mt-6 mb-4">
                <v-btn v-if="mdo == true && mdt == true && mdr == true" :disabled="ps == 400" :style="ps != 400 ? 'border: 2px solid #444 !important;' : ''" outlined height="48px" :loading="fileload" target="_blank" :href="filedata.path" color="#000" class="text-none rounded-pill elevation-0 mr-4"
                  ><span class="subtitle-1 font-weight-medium px-3">View complete applications</span></v-btn
                >
                <v-btn :disabled="mdo != true || mdt != true || mdr != true || fileload || ps == 400" height="48px" @click="setDeclarations()" color="#000" class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Aadhaar eSign</span></v-btn>
              </div>
            </div>
          </div>

          <v-footer padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
            <v-btn :disabled="mdo != true || mdt != true || mdr != true || fileload || ps == 400" height="48px" @click="setDeclarations()" color="#000" block class="white--text text-none rounded-pill elevation-0"
              ><span class="white--text subtitle-1 font-weight-medium px-6">Aadhaar eSign</span></v-btn
            >
          </v-footer>
        </div>

        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/fin.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
        <p class="font-weight-bold fs-18 white--text mt-2">Complete your Aadhaar eSign with digio...</p>
      </div>
    </v-overlay>
  </div>
</template>

<script src="https://app.digio.in/sdk/v11/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v11/digio.js";
// https://app.digio.in/sdk/v10/digio.js
document.body.appendChild(script);

import pdf from "vue-pdf";
import axios from "axios";
import api from "@/api";
export default {
  components: {pdf},

  data: () => ({
    src: "",
    numPages: undefined,
    currentPage: 0,
    pageCount: 0,
    overlaypdf: true,
    isScrolledtoBottom: true,
    offsetTop: 0,
    forstudent: false,
    heisStudent: false,
    zeroAccountCharge: true,
    acceptannexueScreen: false,
    mdo: false,
    mdt: false,
    mdr: false,
    proofupdate: [],
    filedata: {},
    fileload: false,

    stata: [],
    emailis: "",
    overlay: false,
    cardloader: false,
    dataloader: null,
    rejectdata: [],
    ps: null,
    tncurl: "https://dwld.zebuetrade.com/static/Downloads/download_docs/kyc/Disclosure_and_Tariff.pdf",
  }),

  created() {
    this.src = pdf.createLoadingTask(this.tncurl);

    this.dataloader = true;
    // var hyv = localStorage.getItem("HYV_IPV_res");
    // let config;
    // let data;
    // if (hyv) {
    //   data = JSON.stringify({
    //     HYV_IPV_res: hyv ? hyv : "",
    //   });
    //   config = {
    //     method: "post",
    //     url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };
    //   localStorage.removeItem("HYV_IPV_res");
    // } else {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };
    // }

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && p.stage == "submit") {
          if (response.data.income != true && response.data.fno != "no" && response.data.data_type.cam_segis == true) {
            axiosThis.$emit("clicked", 402);
          } else {
            let ways = response.data.stage_wise_approval;
            if (
              response.data.app_status == "rejected" &&
              (ways.nominee == "no" || ways.bank == "no" || ways.bankproof == "no" || ways.digilocker == "no" || ways.email == "no" || ways.mobile == "no" || ways.pan == "no" || ways.panproof == "no" || ways.personal == "no" || ways.segment == "no" || ways.selfie == "no" || ways.signature == "no")
            ) {
              localStorage.setItem("cGFnZWlz", "submit");
              axiosThis.ps = 400;
              axiosThis.rejectdata = response.data;
            } else {
              axiosThis.ps = null;
              axiosThis.rejectdata = [];
              axiosThis.getDeclaration();
              // axiosThis.getPdfFile();
            }
            axiosThis.$emit("clicked", 6, response.data);
            axiosThis.emailis = response.data.email;
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }

        if (response.data.data_type.mandatorydeclarations == true) {
          axiosThis.mdo = true;
          axiosThis.mdr = true;
        } else {
          axiosThis.mdo = false;
          axiosThis.mdr = false;
        }
        axiosThis.dataloader = false;
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  methods: {
    getDeclaration(value, raw) {
      let data;
      if (value == "set") {
        data = {
          mobile: atob(localStorage.getItem("hash")),
          declaration: raw,
        };
      } else {
        data = JSON.stringify({mobile: atob(localStorage.getItem("hash"))});
      }

      let config = {
        method: "post",
        url: `${api.api_url}/getSignUpDeclaration`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          let dec = response.data.declaration;
          if (dec.standing_instructions && dec.standing_instructions.bsda != "Yes") {
            dec.standing_instructions.bsda = "Yes";
            setTimeout(() => {
              axiosThis.getDeclaration("set", dec);
            }, 0);
          } else {
            axiosThis.getPdfFile();
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    onCheckboxClicked() {
      if (this.mdt) {
        this.acceptannexueScreen = true;
      }
    },
    handleScroll(e) {
      var scrollPos = e.target.scrollTop;
      var lastpage = document.getElementById(`pdf${this.numPages - 1}`);
      if (scrollPos <= lastpage.offsetTop) {
        this.isScrolledtoBottom = true;
      } else {
        this.isScrolledtoBottom = false;
      }
    },
    setUpdate(v) {
      window.location.href = `${window.location.origin}/${v}`;
    },
    digioEsign(file, email, session) {
      this.overlay = true;
      var axo = this;
      var options = {
        environment: "production",
        callback: function (t) {
          if (t.message == "Signed Successfully") {
            axo.signFilestatus(t.digio_doc_id, t.message);
          } else if (t.message == "Signing cancelled") {
            axo.signFilestatus(t.digio_doc_id, t.message);
          } else if (t.hasOwnProperty("error_code")) {
            axo.overlay = false;
            axo.$emit("snack", 2, t);
            setTimeout(function () {
              window.location.reload();
            }, 4500);
          }
          console.warn(t);
        },
      };

      var digio = new Digio(options);
      digio.init();
      digio.submit(file, email, session);
    },
    setDeclarations() {
      this.digioEsign(this.filedata.fileid, this.emailis, this.filedata.session);
    },
    signFilestatus(id, stat) {
      let data = JSON.stringify({
        mobileNo: atob(localStorage.getItem("hash")),
        file_id: id,
        e_sign_status: stat,
      });

      let config = {
        method: "post",
        url: api.api_url + "/esignverifystatus",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data.msg == "success") {
            if (stat == "Signed Successfully") {
              axiosThis.esignCompleted();
            } else {
              axiosThis.$emit("snack", 2, stat);
              axiosThis.overlay = false;
            }
          } else {
            axiosThis.$emit("snack", 2, response.data);
            axiosThis.overlay = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    esignCompleted() {
      let data = JSON.stringify({
        mandatorydeclarations: this.mdo && this.mdt && this.mdr ? true : false,
      });
      let config = {
        method: "post",
        url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data.data_type.mandatorydeclarations == true) {
            localStorage.setItem("cGFnZWlz", "submit");
            axiosThis.$emit("clicked", "complete");
          } else {
            axiosThis.overlay = false;
            axiosThis.cardloader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    getPdfFile() {
      this.fileload = true;
      let data = JSON.stringify({
        mobileNo: atob(localStorage.getItem("hash")),
      });

      let config = {
        method: "post",
        url: api.api_url + "/pdf_write",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          localStorage.removeItem("stageR");
          if (response.data.fileid) {
            let data = response.data;
            data.path = `${api.api_img}/static/${data.path.split("/static/")[1]}`;
            axiosThis.fileload = false;
            axiosThis.filedata = data;
          } else if (response.data.msg == "pls check mobile number ") {
            alert("PDF generation error; \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com");
            axiosThis.$emit("snack", 0, "PDF generation error");
          } else if (response.data.msg == "birth place missing") {
            localStorage.setItem("stageR", "birth place missing");
            axiosThis.$emit("snack", 2, "Your birthplace is missing in the personal stage.");
            setTimeout(() => {
              axiosThis.$router.push({name: "personal"});
            }, 1000);
          } else if (response.data.msg == "Kindly verify that Aadhar and Pan have entered the pan stage.") {
            axiosThis.$emit("snack", 0, response.data.msg);
            setTimeout(() => {
              axiosThis.$router.push({name: "pan"});
            }, 1000);
          } else if (response.data.msg == "Enter Nominee details") {
            axiosThis.$emit("snack", 0, response.data.msg);
            setTimeout(() => {
              axiosThis.$router.push({name: "nominee"});
            }, 1000);
          } else {
            axiosThis.$emit("snack", 0, response.data.msg ? response.data.msg : response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
  },

  mounted() {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
};
</script>
