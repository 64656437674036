<template>
  <div>
    <v-row no-glutters class="body-row">
      <v-col cols="12" md="7" class="left-cols pr-md-8" :class="cardloader ? 'poi-n' : ''">
        <p class="subtitle-2 font-weight-bold mb-5 txt-666 d-none d-md-flex">Welcome to Zebu</p>
        <p class="headline font-weight-black mt-4 mt-md-0 mb-2">Open your free Demat & Trading account</p>
        <p class="subtitle-1 font-weight-regular txt-666" :class="Otp_screen ? 'mb-10' : 'mb-15'">Open an account in under 6 clicks and Kick-start your Investment Journey</p>
        <p class="font-weight-medium fs-17 mb-0">
          Enter mobile number
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
            <path
              d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
              fill="#EF5350"
            />
          </svg>
        </p>

        <v-text-field
          placeholder="Enter mobile number to begin"
          :readonly="Otp_screen"
          required
          type="number"
          hide-spin-buttons
          v-model="mobile_num"
          @keypress="phonevalidation($event), (ash = false)"
          counter="10"
          :error-messages="numberErrors"
          @keyup.enter="verifyMobile()"
          class="txt-field"
          @blur="$v.mobile_num.$touch()"
        >
          <template #prepend-inner>
            <div class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M11.6667 0.333008H4.33333C3.89147 0.333537 3.46785 0.509302 3.15541 0.821748C2.84296 1.13419 2.6672 1.55781 2.66667 1.99967V13.9997C2.6672 14.4415 2.84296 14.8652 3.15541 15.1776C3.46785 15.49 3.89147 15.6658 4.33333 15.6663H11.6667C12.1085 15.6658 12.5321 15.49 12.8446 15.1776C13.157 14.8652 13.3328 14.4415 13.3333 13.9997V1.99967C13.3328 1.55781 13.157 1.13419 12.8446 0.821748C12.5321 0.509302 12.1085 0.333537 11.6667 0.333008ZM12.6667 12.9997C12.6667 13.0881 12.6315 13.1729 12.569 13.2354C12.5065 13.2979 12.4217 13.333 12.3333 13.333H3.66667C3.57826 13.333 3.49348 13.2979 3.43097 13.2354C3.36845 13.1729 3.33333 13.0881 3.33333 12.9997V1.99967C3.33333 1.91127 3.36845 1.82648 3.43097 1.76397C3.49348 1.70146 3.57826 1.66634 3.66667 1.66634H12.3333C12.4217 1.66634 12.5065 1.70146 12.569 1.76397C12.6315 1.82648 12.6667 1.91127 12.6667 1.99967V12.9997Z"
                  fill="#121212"
                />
                <path
                  d="M9.66666 4H6.33333C6.24492 4 6.16014 3.94732 6.09763 3.85355C6.03511 3.75979 6 3.63261 6 3.5C6 3.36739 6.03511 3.24021 6.09763 3.14645C6.16014 3.05268 6.24492 3 6.33333 3H9.66666C9.75507 3 9.83985 3.05268 9.90237 3.14645C9.96488 3.24021 10 3.36739 10 3.5C10 3.63261 9.96488 3.75979 9.90237 3.85355C9.83985 3.94732 9.75507 4 9.66666 4Z"
                  fill="#121212"
                />
              </svg>
            </div>
          </template>
          <template #append>
            <a v-if="Otp_screen" style="text-decoration: none; color: #0037b7; font-size: 12px" href="/"><b>Change number</b></a>
          </template>
        </v-text-field>

        <div v-if="Otp_screen" class="mt-6">
          <p class="font-weight-medium fs-17 mb-0">
            Enter 4 digit OTP sent to mobile no.<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path
                d="M5.10423 0.5L4.85634 3.86901L7.95493 2.53944L8.56338 4.17324L5.13803 4.98451C5.30329 5.17981 5.48732 5.38638 5.69014 5.60423C5.89296 5.81455 6.09577 6.02864 6.29859 6.24648C6.50892 6.46432 6.71549 6.6784 6.91831 6.88873C7.12113 7.09906 7.30892 7.29437 7.48169 7.47465L6.12958 8.5L4.25916 5.6831L2.33239 8.5L0.991549 7.47465L3.35775 4.98451L0 4.12817L0.51831 2.53944L3.68451 3.88028L3.38028 0.5H5.10423Z"
                fill="#EF5350"
              />
            </svg>
          </p>

          <v-text-field
            placeholder="0000"
            required
            type="number"
            hide-spin-buttons
            v-model="mobile_otp"
            ref="mobileotp"
            :readonly="cardloader"
            @keypress="OtpValidation($event), (ash = false)"
            @input="otpVerify()"
            @keyup.enter="otpVerify()"
            counter="4"
            :error-messages="otpErrors"
            @blur="$v.mobile_otp.$touch()"
            class="txt-field"
          >
            <template #prepend-inner>
              <div class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clip-path="url(#clip0_419_1733)">
                    <path
                      d="M15 0H7.5C7.23478 0 6.98043 0.105357 6.79289 0.292893C6.60536 0.48043 6.5 0.734784 6.5 1V8.5C6.5 8.59286 6.52586 8.68388 6.57467 8.76287C6.62349 8.84185 6.69334 8.90569 6.77639 8.94721C6.85945 8.98874 6.95242 9.00632 7.0449 8.99798C7.13738 8.98964 7.22572 8.95571 7.3 8.9L9.1665 7.5H15C15.2652 7.5 15.5196 7.39464 15.7071 7.20711C15.8946 7.01957 16 6.76522 16 6.5V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM11 5.5H8.5V4.5H11V5.5ZM14 3H8.5V2H14V3Z"
                      fill="#666666"
                    />
                    <path
                      d="M10.5 8.5V13.5C10.5 13.6326 10.4473 13.7598 10.3536 13.8536C10.2598 13.9473 10.1326 14 10 14H2.5C2.36739 14 2.24021 13.9473 2.14645 13.8536C2.05268 13.7598 2 13.6326 2 13.5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H5.5V1H2.5C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H10C10.3978 16 10.7794 15.842 11.0607 15.5607C11.342 15.2794 11.5 14.8978 11.5 14.5V8.5H10.5Z"
                      fill="#666666"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_419_1733">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </template>
            <template #append>
              <div>
                <span v-if="timerEnabled" class="font-weight-medium txt-666 fs-12">Resend in {{ timerCount }}</span>
                <v-btn v-if="resendText" @click="verifyMobile('resend')" color="#0037B7" text plain x-small>
                  <span class="font-weight-medium fs-12 text-none">Resend OTP</span>
                </v-btn>
              </div>
            </template>
          </v-text-field>
        </div>

        <div class="d-none d-md-flex">
          <v-btn v-if="!Otp_screen" height="48px" :loading="cardloader" :disabled="$v.mobile_num.$invalid || cardloader" @click="verifyMobile(), $v.mobile_num.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-14"
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >
          <v-btn v-else height="48px" :loading="cardloader" :disabled="$v.mobile_otp.$invalid || cardloader || mobile_otp.length != 4" @click="otpVerify(), $v.mobile_otp.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-4"
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >
        </div>
        <p style="position: absolute; bottom: 16px" class="subtitle-2 font-weight-medium txt-666 mb-0 d-none d-md-block">Zebu is secure and private.</p>

        <v-footer padless color="#fff" class="btn-footer btn-footer px-3 pt-3 pb-1 d-md-none">
          <v-btn v-if="!Otp_screen" height="48px" :loading="cardloader" :disabled="$v.mobile_num.$invalid || cardloader" @click="verifyMobile(), $v.mobile_num.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0" block
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >
          <v-btn v-else height="48px" :loading="cardloader" :disabled="$v.mobile_otp.$invalid || cardloader || mobile_otp.length != 4" @click="otpVerify(), $v.mobile_otp.$touch()" color="#000" class="white--text text-none rounded-pill elevation-0" block
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >
        </v-footer>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img v-if="Otp_screen" src="@/assets/mobile2.svg" class="rgt-img" alt="rgt-img" />
          <img v-else src="@/assets/mobile1.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-overlay :value="mainloader" class="rounded-0">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import api from "@/api";
import axios from "axios";
import {validationMixin} from "vuelidate";
import {required, maxLength, between, numeric, minValue} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    mobile_num: {required, maxLength: maxLength(10), numeric, minValue: minValue(6000000000)},
    mobile_otp: {required, between: between(1, 9999), numeric},
  },
  data() {
    return {
      mobile_num: "",
      mobile_otp: "",
      errorOTPDisp: false,
      Otp_screen: false,
      cardloader: false,
      resendloader: false,
      errortext: "",
      resendText: false,

      timerCount: 120,
      timerEnabled: false,
      ash: false,
      mainloader: false,
    };
  },
  methods: {
    phonevalidation($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        // && keyCode !== 46  this if for dot key
        $event.preventDefault();
      }
      if (this.mobile_num.length >= 10) {
        $event.preventDefault();
      }
    },
    OtpValidation($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        // && keyCode !== 46  this if for dot key
        $event.preventDefault();
      }
      if (this.mobile_otp) {
        this.errorOTPDisp = false;
        if (this.mobile_otp.length >= 4) {
          $event.preventDefault();
        }
      }
    },
    verifyMobile(type) {
      if (type != "resend") {
        this.$v.$touch();
        this.ash = true;
        var hash = new Date().toLocaleString();
        localStorage.setItem("sesslog", btoa(hash));
      }
      if (this.mobile_num && this.mobile_num.length == 10 && !this.$v.mobile_num.$invalid) {
        if (type == "resend") {
          this.resendloader = true;
        } else {
          this.cardloader = true;
          this.$v.$reset();
        }
        var axiosThis = this;
        var json_data = {
          mobile: axiosThis.mobile_num,
          login_type: "signup",
        };
        var config = {
          method: "post",
          url: api.api_url + "/getSignupMobile",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        axios(config)
          .then(function (response) {
            var data = response.data;
            if (type == "resend") {
              axiosThis.$emit("snack", 1, "OTP Resent");
              axiosThis.timerEnabled = true;
              axiosThis.timerCount = 120;
              axiosThis.resendText = false;
              axiosThis.resendloader = false;
              setTimeout(() => axiosThis.$refs.mobileotp.$refs.input.focus(), 100);
            } else {
              if (data["data"] == "Client with this details already exists" || data["data"] == "You are already registered with different flow.") {
                axiosThis.Otp_screen = false;
                axiosThis.timerEnabled = false;
                if (data["data"] == "Client with this details already exists") {
                  axiosThis.mainloader = true;
                  setTimeout(() => {
                    window.location.href = "https://go.mynt.in/";
                  }, 600);
                }
              } else if (data["data"] == "OTP has been sent sucessfully") {
                axiosThis.Otp_screen = true;
                axiosThis.timerEnabled = true;
                setTimeout(() => axiosThis.$refs.mobileotp.$refs.input.focus(), 100);
              }
              axiosThis.$emit("snack", data.data == "OTP has been sent sucessfully" ? 1 : 2, data.data);
              axiosThis.cardloader = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
            axiosThis.cardloader = false;
          });
      } else {
        this.cardloader = false;
      }
    },
    otpVerify() {
      var url = new URL(window.location.href);
      var cref = url.searchParams.get("ref");
      sessionStorage.setItem("ref", cref);
      this.$v.$touch();
      this.ash = true;
      this.cardloader = true;
      this.errorOTPDisp = false;
      if (this.mobile_otp && this.mobile_otp.length == 4 && !this.$v.mobile_otp.$invalid) {
        this.$v.$reset();
        var rmcode = sessionStorage.getItem("ref");
        var rmcodee = rmcode != null ? rmcode.toUpperCase() + "/reference" : "";
        var axiosThis = this;
        var json_data = {
          mobile: axiosThis.mobile_num,
          otp: axiosThis.mobile_otp,
          rmcode: rmcodee,
          login_type: "signup",
        };
        var config = {
          method: "post",
          url: api.api_url + "/VerifySignupMobile",
          headers: {"Content-Type": "application/json"},
          data: JSON.stringify(json_data),
        };
        axios(config)
          .then(function (response) {
            var data = response.data;
            localStorage.setItem("hash", btoa(axiosThis.mobile_num));
            if (data["last_session"]) {
              localStorage.setItem("cGFnZWlz", data.data_type && data.data_type["stage"] ? data.data_type["stage"] : "email");
              data.data_type ? axiosThis.$router.push(data.data_type["stage"] ? {name: data.data_type["stage"]} : "/email") : axiosThis.$emit("clicked", "email");
            } else {
              if (data["email"] == "yes") {
                axiosThis.cardloader = false;
                localStorage.setItem("cGFnZWlz", "pan");
                axiosThis.$router.push("/onboarding");
                axiosThis.errorOTPDisp = false;
                axiosThis.errortext = "";
                axiosThis.$emit("snack", 1, "OTP Verified");
              }
              if (data["data"] == "OTP has been verified sucessfully") {
                localStorage.setItem("cGFnZWlz", data.data_type && data.data_type["rekyc"] == "Yes" ? data.data_type["stage"] : "email");
                axiosThis.$emit("clicked", data.data_type && data.data_type["rekyc"] == "Yes" ? data.data_type["stage"] : "email");
                axiosThis.errorOTPDisp = false;
                axiosThis.errortext = "";
                axiosThis.$emit("snack", 1, data["data"]);
              } else if (data["data"] == "Invalid OTP") {
                axiosThis.mobile_otp = null;
                axiosThis.$v.$reset();
                axiosThis.errortext = "OTP is Invalid!";
                axiosThis.cardloader = false;
                axiosThis.errorOTPDisp = true;
                axiosThis.otpErrors;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosThis.$emit("snack", 0, error);
            axiosThis.cardloader = false;
          });
      } else {
        this.errorOTPDisp = false;
        this.cardloader = false;
      }
    },
  },
  computed: {
    numberErrors() {
      const errors = [];
      if (this.mobile_num.length != 10 && !this.ash) {
        this.$v.$reset();
      }
      if (!this.$v.mobile_num.$dirty) return errors;
      !this.$v.mobile_num.required && errors.push("Mandatory Field");
      !this.$v.mobile_num.numeric || (!this.$v.mobile_num.minValue && errors.push("Enter a valid number"));
      return errors;
    },
    otpErrors() {
      const errors = [];
      if (this.mobile_otp) {
        if (this.mobile_otp.length != 4 && !this.ash) {
          this.$v.$reset();
        }
      }
      if (this.errorOTPDisp) {
        errors.push("You have entered the wrong OTP");
        return errors;
      } else {
        if (!this.$v.mobile_otp.$dirty) return errors;
        !this.$v.mobile_otp.required && errors.push("Mandatory Field");
        !this.$v.mobile_otp.between && errors.push("It should be 4 digit OTP");
        return errors;
      }
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false;
          this.resendText = true;
          this.resendloader = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    window.scrollTo(0, 1);
    // this.$emit('clicked', 0)
  },
  created() {
    localStorage.clear();
    sessionStorage.clear();
    var url = new URL(window.location.href);
    var m = url.searchParams.get("mobile");
    if (m) {
      this.mobile_num = m;
      this.verifyMobile();
    }
    var b = url.searchParams.get("bfbug");
    if (b) {
      this.$router.push(`/uid?bfbug=${b}`);
    }
    var p = url.searchParams.get("rekyc");
    if (p) {
      let apb = atob(p).split("||");
      this.mainloader = true;
      let config = {
        method: "post",
        url: api.api_url + "/rekyc",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({pan: apb[0], ids: apb[1]}),
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data && response.data.mobile) {
            localStorage.setItem("hash", btoa(response.data.mobile));
            localStorage.setItem("cGFnZWlz", response.data.datatype.stage);
            if (response.data.datatype.stage == "onboard") {
              let data = JSON.stringify({
                rekyc: "Yes",
                stage: "pan",
              });

              let config = {
                method: "post",
                url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")) + `&type=add`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  localStorage.setItem("L8nbe2ihTzm", btoa(apb[0]));
                  if (response.data && response.data.data_type) {
                    axiosThis.$router.push({name: response.data.data_type.stage});
                  } else {
                    axiosThis.mainloader = false;
                    axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  axiosThis.mainloader = false;
                  axiosThis.$emit("snack", 0, error);
                });
            } else {
              axiosThis.$router.push({name: response.data.datatype.stage});
            }
          } else {
            axiosThis.mainloader = false;
            axiosThis.$emit("snack", 2, response.data.msg ? response.data.msg : response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.mainloader = false;
          axiosThis.$emit("snack", 0, error);
        });
      // atob(panno)
      // QUtEUFI4MjU3Sw==
      // https://oa.mynt.in?rekyc=TEZSUFM3NDI2SA==
      // http://192.168.5.107:8081?rekyc=TEZSUFM3NDI2SA==
    }
  },
};
</script>
